import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useDialogContext } from '../Context';

import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { Roles, Statuses } from 'types';
import { dashboardPageSelector } from '../../DashboardPage/selectors';
import { DateTimePicker } from '@material-ui/pickers';
import {
  TechnicalVisitDataInterface,
  TechnicalVisitInterface,
} from 'app/types';
import { useFormikContext } from 'formik';
import { actions } from 'app/slice';

export const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setValidationSchema, variant } = useDialogContext();
  const { setFieldValue, values } = useFormikContext<TechnicalVisitInterface>();
  const { onSmallDevice, lists } = useSelector(appDataSelector);
  const { users } = useSelector(dashboardPageSelector);
  const [usersOptions, setUsersOptions] = React.useState([] as MenuOption[]);
  const { resourceDialogParams } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );
  const [listKeys] = React.useState(['type_of_selling']);

  React.useEffect(() => {
    dispatch(actions.fetchLists(listKeys));
  }, [dispatch, listKeys]);

  React.useEffect(() => {
    const options = {};
    // eslint-disable-next-line array-callback-return
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(() => {
    const options = users
      .filter(
        (user) =>
          [Roles.BASIC, Roles.SUBCONTRACTING].includes(user.role) &&
          user.status === Statuses.ENABLED,
      )
      .map((user) => ({
        value: user.id,
        displayedValue: user.name,
      })) as MenuOption[];
    setUsersOptions(options);
  }, [setUsersOptions, users]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          technician: yup
            .string()
            .required(t(translations.formErrors.required)),
          selling: yup.string().required(t(translations.formErrors.required)),
          typeOfSelling: yup
            .number()
            .required(t(translations.formErrors.required)),
          appointmentAt: yup
            .date()
            .required(t(translations.formErrors.required)),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        {users && users.length > 0 && (
          <FormikInput
            name="technician"
            label={t(translations.TechnicalVisitForms.labels.technician)}
            extraAttr={{
              ...variant,
              options: usersOptions,
              style: { width: '100%' },
            }}
            containerStyle={{ flex: '1' }}
            component={SimpleSelect}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={onSmallDevice ? 'column' : 'row'}
      ></Box>

      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="selling"
          label={t(translations.TechnicalVisitForms.labels.selling)}
          extraAttr={{
            ...variant,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        {!_.isEmpty(listsOptions['type_of_selling']) && (
          <FormikInput
            name="typeOfSelling"
            label={t(translations.TechnicalVisitForms.labels.typeOfSelling)}
            extraAttr={{
              ...variant,
              options: listsOptions['type_of_selling'] || [],
              style: { width: '100%' },
            }}
            containerStyle={{ flex: '3' }}
            component={SimpleSelect}
          />
        )}
        <FormikInput
          name="appointmentAt"
          label={t(translations.TechnicalVisitForms.labels.appointmentAt)}
          extraAttr={{
            ...variant,
            ampm: false,
            format: 'dd/MM/yy HH:mm',
            onChange: (date) => setFieldValue('appointmentAt', date),
          }}
          containerStyle={{ flex: '2' }}
          component={DateTimePicker}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={onSmallDevice ? 'column' : 'row'}
        justifyContent="space-around"
      >
        {values?.id && !resourceDialogParams.isDuplicate && (
          <>
            <p>
              Identifiant :{' '}
              <Typography
                color="textPrimary"
                variant="subtitle2"
                component="code"
                paragraph
              >
                {values?.id}
              </Typography>
            </p>
            <Button
              onClick={() => navigator.clipboard.writeText(values.id!)}
              variant="outlined"
              color="secondary"
              size="small"
            >
              {t(translations.TechnicalVisitForms.buttons.copy)}
            </Button>
            <Button
              onClick={() => {
                history.push(`/technical-visit/${values.id!}`);
                dispatch(
                  actions.resetResourceDialogParams(
                    resourceDialogParams.refreshResources,
                  ),
                );
              }}
              variant="outlined"
              color="secondary"
              size="small"
            >
              {t(translations.TechnicalVisitForms.buttons.open)}
            </Button>
          </>
        )}
      </Box>
    </>
  );
};
