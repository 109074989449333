import { States } from 'types';

/* --- STATE --- */
export enum Resources {
  TECHNICAL_VISIT = 'technicalVisit',
  CUSTOMER = 'customer',
  ROOF_FRAME = 'roofFrame',
  ELECTRICITY = 'electricity',
  GENERATOR = 'generator',
  RADIATOR = 'radiator',
  EQUIPMENT_HANDLES = 'equipmentHandles',
  DOCUMENT_DT = 'documentDt',
  SELF_CONSUMPTION = 'selfConsumption',
}

export enum addOrUpdateResources {
  TECHNICAL_VISIT = Resources.TECHNICAL_VISIT,
}

export interface TechnicalVisitPageState {
  technicalVisit: TechnicalVisitInterface;
  customer: CustomerInterface;
  roofFrame: RoofFrameInterface[];
  radiator: RadiatorInterface[];
  selfConsumption: SelfConsumptionInterface[];
  equipmentHandles: EquipmentInterface[];
  electricity: ElectricityInterface;
  generator: GeneratorInterface;
  documentDt: DocumentDtInterface;
  images: { [key: string]: Image[] };
  technicalVisitId?: string;
}

export interface Image {
  id: string;
  name: string;
  signedUrl: string;
  path: string;
  resources: string[];
  base64File?: string;
  resourceName?: string;
  resourceId?: string;
  resourceNameTv?: string;
  resourceIdTv?: string;
  title?: string;
  createdAt?: string;
  updatedAt?: string;
  extension?: string;
  type?: string;
}

export interface addFile {
  file?: Partial<Image>;
  formData?: FormData;
}

export interface ResourcePushes {
  resource: Resources;
  values: any;
}

export interface ResourceSplices {
  resource: Resources;
  index: number;
}

export interface CustomerInterface {
  id?: string;
  firstname: string;
  lastname: string;
  address?: string;
  city?: string;
  zip?: number;
  phone?: string;
  mobile?: string;
  mail?: string;
  attributedTo?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface TechnicalVisitInterface {
  id?: string;
  technician: string;
  technicianName?: string;
  timestamp?: Date;
  internetProvider: string;
  houseArea: string;
  selling: string;
  typeOfSelling?: number;
  typeOfSellingKey?: string;
  appointmentAt: Date;
  cadastralPlot: string;
  comment?: string;
  createdAt?: string;
  updatedAt?: string;
  resourcesUpdatedAt?: string;
  difficultAccess?: boolean;
  needCarrycotLadder?: boolean;
  needTrench?: boolean;
  needSecurityNet?: boolean;
  roofAccessSpace?: boolean;
  difficultAccessComment?: string;
  needCarrycotLadderComment?: string;
  needTrenchComment?: string;
  needSecurityNetComment?: string;
  roofAccessSpaceComment?: string;
  canView?: boolean;
  status: States;
}

export interface RoofFrameInterface {
  id?: string;
  orientation?: number;
  degreesOrientation?: number;
  pitch?: number;
  roofEdgeHeight?: number;
  installationType?: number;
  roofType?: number;
  roofSubType?: number;
  frameType?: number;
  frameMaterial?: number;
  roofThickness?: number;
  centerDistance?: number;
  height?: number;
  conditionOfFrame?: number;
  conditionOfRoof?: number;
  ridgeType?: number;
  shoreType?: number;
  battenThickness?: string;
  frameLength?: number;
  crawlingFrame?: number;
  furtherInformation?: string;
  obstacleOnRoof?: string;
  enoughMaterialAvailable?: boolean;
  insulationBetween?: boolean;
  roofFrameTitle?: any;
  technicalVisitId?: number;
  title?: string;
  minusOneHundredEighty?: string;
  minusOneHundredFifty?: string;
  minusOneHundredTwenty?: string;
  minusNinety?: string;
  minusSixty?: string;
  minusFortyFive?: string;
  minusThirty?: string;
  zero?: string;
  oneHundredFifty?: string;
  oneHundredTwenty?: string;
  ninety?: string;
  sixty?: string;
  fortyFive?: string;
  thirty?: string;
}

export interface GeneratorInterface {
  id?: string;
  annualConsumption?: number;
  nbrOfModule?: number;
  unitPowerModule?: number;
  typeConnection?: number;
  storageBatteryPower?: number;
  ecsProduction?: number;
  ecsConnection?: number;
  mainHeatingType?: number;
  secondaryHeatingType?: number;
  remoteWithDisplay?: boolean;
  btBrandModel?: string;
  presenceRoomThermostat?: number;
  furtherInformation?: string;
}

export interface RadiatorInterface {
  id?: string;
  localisation?: string;
  needRegulate?: boolean;
  type?: number;
  subType?: number;
  title?: string;
  technicalVisitId?: number;
}

export interface EquipmentInterface {
  id?: string;
  name?: string;
  type?: number;
  isOutlet?: boolean;
  title?: string;
  technicalVisitId?: number;
}

export interface ElectricityInterface {
  id?: string;
  supply?: number;
  rackingPower?: number;
  wallCrossing?: boolean;
  slabCrossing?: boolean;
  counterLocation?: number;
  circuitBreakerLocation?: number;
  pdlNumber?: string;
  distanceCounterPanel?: number;
  distanceDmiPanel?: number;
  distanceDmiField?: number;
  presenceId?: boolean;
  freeTerminalPanel?: boolean;
  subscriptionType?: number;
  slackPeriods?: string;
  groundConnectionValue?: number;
  networkManager?: number;
  connectionType?: number;
  externalEnclosureType?: number;
  resumptionConnectionValue?: boolean;
  technicalVisitId?: number;
  furtherInformation?: string;
  selfConsumption?: boolean;
  totalResale?: boolean;
  inverterMarkResale?: string;
  panelNbrResale?: number;
  powerAlreadyInstalledResale?: number;
  installationYearResale?: number;
  connectionRequestNumberResale?: string;
  maxInjectionPower?: boolean;
}
export interface SelfConsumptionInterface {
  id?: string;
  technicalVisitId?: number;
  selfConsumptionType?: number;
  inverterMark?: string;
  envoyNumber?: string;
  panelNbr?: number;
  acProtection?: number;
  powerAlreadyInstalled?: number;
  existingEnvoy?: number;
  boxFieldSection?: string;
  typeFieldSection?: number;
  connectionRequestNumber?: string;
  installationYear?: number;
}

export interface DocumentDtInterface {
  id?: string;
  technicalVisitId?: number;
  oneline?: number;
  emission?: number;
  furtherInformation?: string;
}

export interface StepForm {
  resource: Resources;
  stepLabel: string;
  refresh: boolean;
}

export interface AddOrUpdateItemParams<T> {
  resource: Resources;
  item: Partial<T>;
  key?: string;
  index?: number;
}
export interface RemoveItemParams {
  resource: Resources;
  id: string;
  tags: object;
}

export interface ResourceStepper {
  title?: string;
  resources?: StepForm[];
  refreshResources?: [Resources];
  items?: {
    [key in Extract<
      Resources,
      | Resources.TECHNICAL_VISIT
      | Resources.CUSTOMER
      | Resources.ROOF_FRAME[]
      | Resources.EQUIPMENT_HANDLES[]
      | Resources.ELECTRICITY
      | Resources.GENERATOR
      | Resources.DOCUMENT_DT
    >]: any;
  };
  foreignKey?: any;
  readOnly?: boolean;
}

export interface AddOrUpdateItemParams<T> {
  resource: Resources;
  item: Partial<T>;
  key?: string;
  index?: number;
}

export interface SetItemParams {
  resource: string;
  item: any;
  index?: number;
}

export interface AddOrUpdateImageParams {
  data?: Partial<Image>;
  id?: string;
  collection: string;
}

export interface DeleteImageParams {
  id: string;
  collections?: string[];
  data: any;
}

export interface SetImagesParams {
  images: Image[];
  collection: string;
}
export interface SetItemParams {
  resource: string;
  item: any;
  foreignKey?: any;
}

export interface ExportImagesParams {
  name: string;
  id: string;
  folderStructure: { path: string; files: string[] }[];
}

export type ContainerState = TechnicalVisitPageState;
