import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  UserInterface,
  ENABLED,
  DISABLED,
  DELETED,
  userInitialState,
  Roles,
  BASIC,
  SUPERADMIN,
  ADMIN_LYON,
  ADMIN_NOVITA,
  ADMIN_AVIGNON,
  BASIC_MANAGER,
  SUPPLIER,
} from 'types';
import { translations } from 'locales/i18n';
import { actions as appRowActions } from 'app/slice';
import { actions } from '../slice';
import { settingsPageSelector } from '../selectors';
import { Resources } from '../types';

import { Box, Button } from '@material-ui/core';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import {
  VirtualizedTable,
  RowActions,
  ColumnData,
} from 'app/components/VirtualizedTable';
import { MultipleSelect } from 'app/components/CustomSelect/MultipleSelect';
import { StatusIcon } from '../component/StatusIcon';
import { appDataSelector } from 'app/selectors';

export const UsersManager = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { users } = useSelector(settingsPageSelector);
  const { isSuperadmin } = useSelector(appDataSelector);
  const [queryParams, setQueryParams] = React.useState<{ role: Roles[] }>({
    role: [],
  });
  const [
    confirmStatusDialogProps,
    setConfirmStatusDialogProps,
  ] = React.useState({
    isOpen: false,
    action: RowActions.ENABLE,
    user: {} as Partial<UserInterface>,
  });

  const debouncedSetFilters = _.debounce(setQueryParams, 500);

  const setUserDialogParams = (user: UserInterface) =>
    dispatch(
      appRowActions.setUserDialogParams({
        user: user,
        isOpen: true,
        asProfile: false,
      }),
    );

  const closeConfirmStatusDialog = () =>
    setConfirmStatusDialogProps({
      ...confirmStatusDialogProps,
      isOpen: false,
      user: {},
    });

  const onChangeStatus = ({ id }: UserInterface, action: RowActions) =>
    setConfirmStatusDialogProps({
      isOpen: true,
      action,
      user: {
        id,
        status:
          action === 'disable'
            ? DISABLED
            : action === RowActions.DELETE
            ? DELETED
            : ENABLED,
      },
    });

  const onConfirmChangeStatus = () => {
    dispatch(
      actions.addOrUpdateItem({
        resource: Resources.USERS,
        item: confirmStatusDialogProps.user,
      }),
    );
    closeConfirmStatusDialog();
  };

  React.useEffect(() => {
    dispatch(
      actions.fetchItems({
        resource: Resources.USERS,
        queryParams,
      }),
    );
  }, [dispatch, queryParams]);

  const columns: ColumnData[] = [
    {
      widthRatio: 0.3,
      label: t(translations.UserTable.name),
      dataKey: 'name',
    },
    {
      widthRatio: 0.3,
      label: (
        <MultipleSelect
          label={t(translations.UserForms.labels.role)}
          options={[
            ADMIN_LYON,
            ADMIN_NOVITA,
            ADMIN_AVIGNON,
            BASIC_MANAGER,
            SUPPLIER,
            BASIC,
            ...(isSuperadmin ? [SUPERADMIN] : []),
          ].map((role) => ({
            value: role,
            displayedValue: `${t(translations.UserForms.roles[role])}`,
          }))}
          name="role"
          value={queryParams.role}
          variant={'outlined'}
          onChange={(value) => {
            setQueryParams((current) => ({
              ...current,
              role: value as any,
            }));
          }}
        />
      ),
      dataKey: 'role',
      cellRenderer: ({ cellData }) => t(translations.UserForms.roles[cellData]),
    },
    {
      widthRatio: 0.4,
      label: t(translations.UserTable.email),
      dataKey: 'email',
    },
    {
      width: 100,
      label: t(translations.UserTable.status),
      dataKey: 'status',
      cellRenderer: ({ cellData: status }) => (
        <StatusIcon
          status={status}
          label={t(translations.StatusesIcons[status])}
        />
      ),
      extraStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const icons = [
    {
      type: RowActions.EDIT,
      label: t(translations.TableRowActions.showEdit),
      handler: ({ rowData }) => setUserDialogParams(rowData as UserInterface),
    },
    ...[
      RowActions.DISABLE,
      RowActions.ENABLE,
      RowActions.DELETE,
      RowActions.RESTORE,
    ].map((type) => ({
      type: type,
      label: t(translations.TableRowActions[type]),
      handler: ({ rowData }) => onChangeStatus(rowData, type),
      isVisible: (user: UserInterface) =>
        [
          ...([RowActions.DISABLE, RowActions.DELETE].includes(type)
            ? [ENABLED]
            : []),
          ...([RowActions.ENABLE, RowActions.DELETE].includes(type)
            ? [DISABLED]
            : []),
          ...(type === RowActions.RESTORE ? [DELETED] : []),
        ].includes(user.status),
    })),
  ];

  return (
    <>
      <Box display="flex">
        {isSuperadmin && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineRounded />}
            onClick={() =>
              setUserDialogParams({
                ...userInitialState,
              })
            }
          >
            {t(translations.UserTable.addUser)}
          </Button>
        )}
      </Box>

      <Box display="flex" overflow="auto hidden" flexGrow="1">
        <VirtualizedTable
          rowCount={users.length}
          rowGetter={({ index }) => users[index]}
          headerHeight={96}
          onRowClick={({ rowData }) =>
            setUserDialogParams(rowData as UserInterface)
          }
          columns={columns}
          actionsParams={{
            icons,
            maxVisibleIcons: 3,
          }}
        />
      </Box>
      <ConfirmDialog
        title={t(translations.TableRowActions[confirmStatusDialogProps.action])}
        content={t(
          translations.ConfirmDialogActionsContent[
            confirmStatusDialogProps.action
          ],
          {
            item: t(translations.ConfirmDialogActionsContentItem.user),
          },
        )}
        isOpen={confirmStatusDialogProps.isOpen}
        cancelLabel={t(translations.ConfirmDialog.cancel)}
        confirmLabel={t(translations.ConfirmDialog.confirm)}
        onClose={() => closeConfirmStatusDialog()}
        onConfirm={onConfirmChangeStatus}
      />
    </>
  );
};
