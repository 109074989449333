import { v4 as uuid } from 'uuid';
import { PENDING } from 'types';
import {
  TechnicalVisitInterface,
  CustomerInterface,
  RoofFrameInterface,
  ElectricityInterface,
  GeneratorInterface,
  RadiatorInterface,
  EquipmentInterface,
  SelfConsumptionInterface,
  DocumentDtInterface,
} from './types';

export const customer: CustomerInterface = {
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  zip: undefined,
  phone: '',
  attributedTo: 'domuneo',
  mobile: '',
  mail: '',
};
export const technicalVisit: TechnicalVisitInterface = {
  technician: '',
  internetProvider: '',
  houseArea: '',
  cadastralPlot: '',
  selling: '',
  appointmentAt: new Date(),
  status: PENDING,
};

export const roofFrame: RoofFrameInterface = {
  orientation: undefined,
};

export const electricity: ElectricityInterface = {
  supply: undefined,
  slabCrossing: false,
  wallCrossing: false,
  presenceId: false,
  freeTerminalPanel: false,
  selfConsumption: false,
};

export const generator: GeneratorInterface = {
  annualConsumption: undefined,
};

export const radiator: RadiatorInterface = {
  localisation: '',
  needRegulate: false,
};

export const equipment: EquipmentInterface = {
  name: 'eth',
  isOutlet: true,
  title: 'prise',
};
export const selfConsumption: SelfConsumptionInterface = {};

export const documentDt: DocumentDtInterface = {};
