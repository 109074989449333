import localforage from 'utils/localforage';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, cps, put, select, takeLatest } from 'redux-saga/effects';
import _, { forEach, merge } from 'lodash';
import jwtDecode from 'jwt-decode';

import { api } from 'utils/api';
import { actions } from 'app/slice';
import { actions as appActions } from 'app/slice';
import { UserInterface } from 'types';
import { appDataSelector } from 'app/selectors';
import {
  AddOrUpdateItemParams,
  AddOrUpdateListParams,
  FetchTechnicalVisitsParams,
  Resources,
  SearchTechnicalVisitsParams,
} from 'app/types';
import { dashboardPageSelector } from 'app/containers/DashboardPage/selectors';
import { updateOrAddToCollection } from 'utils/transform';
import { technicalVisit } from 'app/containers/TechnicalVisitPage/initialStates';
import { cacheTechnicalVisit } from './caching';

export function* synchronize() {
  // try {
  const { technicalVisitsSyncablePaths, lastMergingAt } = yield select(
    appDataSelector,
  );
  const maxParallelCalls = 3;
  let syncHaveBeenPaused = false;
  const setPausedCallback = () => {
    syncHaveBeenPaused = true;
  };

  try {
    for (const technicalVisitId of Object.keys(technicalVisitsSyncablePaths)) {
      yield put(actions.setSyncStatus('syncing'));

      if (!lastMergingAt || lastMergingAt + 900 * 1000 < new Date().getTime()) {
        yield cacheTechnicalVisit(technicalVisitId);
        yield put(actions.setLastMergingAt());
      }

      const cachedTechnicalVisit = JSON.parse(
        JSON.stringify(
          (yield select(appDataSelector)).technicalVisitsCached[
            technicalVisitId
          ],
        ),
      );
      const {
        technicalVisitsSyncablePaths: {
          [technicalVisitId]: syncablePathsConfigs,
        },
      } = yield select(appDataSelector);

      const chunkedSyncablePaths = Object.keys(syncablePathsConfigs).reduce(
        (acc, path, idx) => {
          const chunkIdx = Math.floor(idx / maxParallelCalls);
          acc[chunkIdx] = [...(acc[chunkIdx] || []), path];
          return acc;
        },
        [] as any[][],
      );

      for (const syncablePaths of chunkedSyncablePaths) {
        yield all(
          syncablePaths.map((path) =>
            syncItem(
              technicalVisitId,
              path,
              cachedTechnicalVisit,
              syncablePathsConfigs[path],
              setPausedCallback,
            ),
          ),
        );
      }

      yield put(actions.addOrUpdateCachedTv(cachedTechnicalVisit));
    }
  } catch (e) {
    console.log(e);
    setPausedCallback();
  }

  if (syncHaveBeenPaused) {
    yield put(actions.setSyncStatus('paused'));
    const pauseQueueFor = async (timeInMs: number) =>
      await new Promise((resolve) =>
        setTimeout(() => resolve(undefined), timeInMs),
      );
    yield call(pauseQueueFor, 120000);
  }

  yield put(
    actions.setSyncStatus(syncHaveBeenPaused ? 'resuming' : 'awaiting'),
  );
  yield put(
    actions[
      syncHaveBeenPaused
        ? 'incrementSyncFailureCounter'
        : 'resetSyncFailureCounter'
    ](),
  );
}

function* syncItem(
  technicalVisitId,
  syncablePath,
  cachedTechnicalVisit,
  syncablePathConfig,
  setPausedCallback,
) {
  if (!syncablePathConfig) return;
  try {
    const { action, data, extra } = syncablePathConfig;
    const apiAction = action === 'create' ? 'add' : action;
    const resource =
      syncablePath.split('.')[0] === 'images'
        ? 'files'
        : syncablePath.split('.')[0];

    let updatedItem = yield call(api[resource][apiAction], data, extra);

    if (apiAction !== 'delete' && (!updatedItem || !updatedItem.id))
      throw new Error('Request failed');

    if (apiAction !== 'delete') {
      const itemPath = syncablePath
        .split('.')
        .reduce((currentPath, pathPart, index) => {
          if (index === 0) return currentPath;

          const item = _.get(cachedTechnicalVisit, currentPath);

          if (_.isArray(item)) {
            const index = _.findIndex(item, ({ id }) => id === pathPart);
            return `${currentPath}.[${index >= 0 ? index : item.length}]`;
          }
          return `${currentPath}.${pathPart}`;
        }, syncablePath.split('.')[0]);

      _.set(cachedTechnicalVisit, itemPath, updatedItem);

      if (resource === 'files') {
        yield call(async () => {
          await localforage.removeItem(data.id);
        });
      }
    }

    yield put(
      actions.removeSyncablePath({
        id: technicalVisitId,
        path: syncablePath,
      }),
    );
  } catch (e: any) {
    console.log(e.message);

    if (['404', '409'].includes(e.message)) {
      yield put(
        actions.removeSyncablePath({
          id: technicalVisitId,
          path: syncablePath,
        }),
      );
      //MAYBE REMOVE ITEM IF IS EXISTING
    }

    setPausedCallback();
  }
}
