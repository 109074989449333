import localforage from 'localforage';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { api } from 'utils/api';
import { actions } from './slice';
import { actions as appActions } from 'app/slice';
import * as appSaga from 'store/sagas/appData';
import { technicalVisitPageSelector } from './selectors';
import {
  AddOrUpdateItemParams,
  AddOrUpdateImageParams,
  DeleteImageParams,
  RemoveItemParams,
  ExportImagesParams,
  Resources,
} from './types';
import { appDataSelector } from 'app/selectors';
import { SyncablePathActions, SyncablePathParams } from 'app/types';
import { ObjectID } from 'bson';

localforage.config({ name: 'files' });

function* fetchTechnicalVisit({ payload: id }: PayloadAction<string>) {
  const {
    syncStatus: { current: previousState },
  } = yield select(appDataSelector);
  // yield put(appActions.setSyncStat0us('caching'));
  yield appSaga.cacheTechnicalVisit(id);
  // yield put(appActions.setSyncStatus(previousState));

  // try {
  //   let {
  //     technicalVisitsCached: { [id]: cachedTechnicalVisit },
  //   } = yield select(appDataSelector);

  //   if (cachedTechnicalVisit)
  //     yield put(actions.setTechnicalVisit(cachedTechnicalVisit));

  //   const fetchedTechnicalVisit = yield call(api.technicalVisit.one, id);

  //   const technicalVisit =
  //     cachedTechnicalVisit &&
  //     cachedTechnicalVisit.technicalVisit.updatedAt >=
  //       fetchedTechnicalVisit.technicalVisit.updatedAt &&
  //     cachedTechnicalVisit.technicalVisit.resourcesUpdatedAt >=
  //       fetchedTechnicalVisit.technicalVisit.resourcesUpdatedAt
  //       ? cachedTechnicalVisit
  //       : fetchedTechnicalVisit;

  //   yield put(actions.setTechnicalVisit(technicalVisit));

  //   yield put(appActions.addOrUpdateCachedTv(technicalVisit));
  // } catch (e) {
  //   console.log(e);
  // }
}

function* generatePdf(action: PayloadAction<any>) {
  try {
    const data = action.payload;
    yield call(api.technicalVisit.generate, data);
  } catch (e) {
    console.log(e);
  }
}

// function* fetchImages({ payload }: PayloadAction<string>) {
//   if (payload) yield fetchImagesRequest(payload);
// }

// function* fetchImagesRequest(collection: string) {
//   try {
//     const images = yield call(api.files.all, collection);
//     const { images: collections } = yield select(technicalVisitPageSelector);
//     if (
//       images &&
//       images.length > 0 &&
//       JSON.stringify(images) !== JSON.stringify(collections[collection])
//     ) {
//       yield put(
//         actions.setImages({
//           images: images,
//           collection,
//         }),
//       );
//     }
//   } catch (e) {
//     console.error(e);
//   }
// }

function* addOrUpdateImage({ payload }: PayloadAction<AddOrUpdateImageParams>) {
  try {
    const { technicalVisitId } = yield select(technicalVisitPageSelector);
    const {
      technicalVisitsCached: {
        [technicalVisitId]: { images },
      },
    } = yield select(appDataSelector);

    const index = payload.id
      ? _.findIndex(images[payload.collection], ['id', payload.id])
      : images[payload.collection]?.length || 0;
    const currentImageState = images[payload.collection]?.[index];
    const cachedImage = {
      ...(currentImageState || {}),
      ...payload.data,
      id: payload.id || payload.data?.id || new ObjectID().toString(),
      resources:
        payload.data
          ?.path!.split('/')
          .map(
            (v, i, arr) =>
              `${(arr[i] = arr[i - 1] ? `${arr[i - 1]}/${v}` : v)}`,
          ) ||
        currentImageState?.resources ||
        payload.data?.resources,
    };

    if (cachedImage && cachedImage.id) {
      const imagesCollectionCopy = JSON.parse(
        JSON.stringify(images[payload.collection] || []),
      );
      imagesCollectionCopy[index] = cachedImage;

      yield put(
        appActions.setCachedImages({
          images: imagesCollectionCopy,
          collectionName: payload.collection,
          technicalVisitId,
        }),
      );
    }

    yield put(
      appActions.addSyncablePath({
        id: technicalVisitId,
        path: ['images', payload.collection, cachedImage.id].join('.'),
        params: {
          action: payload.id ? 'update' : 'create',
          data: cachedImage,
        },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* deleteImage({ payload }: PayloadAction<DeleteImageParams>) {
  try {
    const { technicalVisitId } = yield select(technicalVisitPageSelector);
    const {
      technicalVisitsCached: {
        [technicalVisitId]: { images },
      },
    } = yield select(appDataSelector);

    const imagesCopy = JSON.parse(JSON.stringify(images));

    yield all(
      payload.collections?.map(function* (collectionName) {
        if (imagesCopy[collectionName]) {
          const imagesCollection = imagesCopy[collectionName].filter(
            ({ id }) => id !== payload.id,
          );
          yield imagesCollection.length > 0
            ? put(
                appActions.setCachedImages({
                  images: imagesCollection,
                  collectionName,
                  technicalVisitId,
                }),
              )
            : put(
                appActions.unsetCachedImages({
                  collectionName,
                  technicalVisitId,
                }),
              );

          yield call(async () => {
            await localforage.removeItem(payload.id);
          });

          yield put(
            appActions.addSyncablePath({
              id: technicalVisitId,
              path: ['images', collectionName, payload.id].join('.'),
              params: {
                action: 'delete',
                data: payload.id,
                extra: payload.data,
              },
            }),
          );
        }
      }) || [],
    );

    // const technicalVisit = yield select(technicalVisitPageSelector);
    // yield put(appActions.addOrUpdateCachedTv(technicalVisit));
  } catch (e) {
    console.error(e);
  }
}

function* removeItem(action: PayloadAction<RemoveItemParams>) {
  try {
    let { resource, id, tags } = action.payload;
    const { technicalVisitId } = yield select(technicalVisitPageSelector);
    const {
      technicalVisitsCached: { [technicalVisitId]: cachedTechnicalVisit },
    } = yield select(appDataSelector);

    // console.log(
    //   cachedTechnicalVisit,
    //   setOrUnsetItemResource('unset', cachedTechnicalVisit, resource, { id }),
    // );
    // yield put(actions.unsetItemResource({ resource, id }));
    yield put(
      appActions.addOrUpdateCachedTv(
        setOrUnsetItemResource('unset', cachedTechnicalVisit, resource, { id }),
      ),
    );
    yield all(
      Object.keys(cachedTechnicalVisit.images).map(function* (collection) {
        if (collection.includes(id)) return yield deleteCollection(collection);
      }),
    );

    yield put(
      appActions.addSyncablePath({
        id: technicalVisitId,
        path: [
          resource,
          ...(_.isArray(cachedTechnicalVisit[resource]) ? [id] : []),
        ].join('.'),
        params: {
          action: 'delete',
          data: id,
          extra: tags,
        },
      }),
    );
  } catch (e) {
    console.log(e);
  }
}

function* deleteCollection(collectionName: string) {
  try {
    const { technicalVisitId } = yield select(technicalVisitPageSelector);
    const {
      technicalVisitsCached: {
        [technicalVisitId]: {
          images: { [collectionName]: images },
        },
      },
    } = yield select(appDataSelector);

    yield all(
      images.map(function* (file) {
        return yield deleteImage(file.id);
      }),
    );
    // return yield put(actions.unsetImages(collectionName));
  } catch (e) {
    console.error(e);
  }
}

function* addOrUpdateItem<T>({
  payload: { resource, item, index, key = 'id' },
}: PayloadAction<AddOrUpdateItemParams<T>>) {
  try {
    const action = (item as any).createdAt ? 'update' : 'create';
    const { technicalVisitId } = yield select(technicalVisitPageSelector);
    const {
      technicalVisitsCached: { [technicalVisitId]: cachedTechnicalVisit },
    } = yield select(appDataSelector);
    // const updatedAt = `${new Date().toISOString()}`;
    // let technicalVisit = yield select(technicalVisitPageSelector);
    item = {
      id: uuid(),
      ...item,
    };

    yield put(
      appActions.addOrUpdateCachedTv(
        setOrUnsetItemResource('set', cachedTechnicalVisit, resource, item),
      ),
    );

    yield put(
      appActions.addSyncablePath({
        id: technicalVisitId,
        path: [
          resource,
          ...(_.isArray(cachedTechnicalVisit[resource])
            ? [(item as any).id]
            : []),
        ].join('.'),
        params: {
          action,
          data: item,
        },
      }),
    );

    // yield updateCacheAndSyncablePaths({
    //   path: [
    //     resource,
    //     ...(_.isArray(technicalVisit[resource]) ? [(item as any).id] : []),
    //   ].join('.'),
    //   updatedAt,
    //   params: {
    //     action,
    //     data: item,
    //   },
    // });
  } catch (e) {
    console.log(e);
  }
}

function setOrUnsetItemResource(action, technicalVisit, resource, item) {
  let path = resource;
  if (Array.isArray(technicalVisit[resource]) && item?.id) {
    let index = technicalVisit[resource].findIndex(
      ({ id }) => id === item.id || !id,
    );
    path += `.[${index >= 0 ? index : technicalVisit[resource.length]}]`;
  }

  let technicalVisitShallowCopy = JSON.parse(JSON.stringify(technicalVisit));

  if (action === 'set') _.set(technicalVisitShallowCopy, path, item);
  if (action === 'unset')
    technicalVisitShallowCopy = _.omit(technicalVisitShallowCopy, path);

  if (Array.isArray(technicalVisit[resource])) {
    technicalVisitShallowCopy[resource] = _.compact(
      technicalVisitShallowCopy[resource],
    );
  }

  return technicalVisitShallowCopy;
}

// function* updateCacheAndSyncablePaths({
//   path,
//   updatedAt,
//   params,
// }: {
//   path: string;
//   updatedAt: string;
//   params: SyncablePathParams;
// }) {
//   const { technicalVisitId } = yield select(technicalVisitPageSelector);
//   // const {
//   //   technicalVisitsCached: { [technicalVisitId]: cachedTechnicalVisit },
//   // } = yield select(appDataSelector);

//   // yield put(
//   //   actions.setItemResource({
//   //     resource: Resources.TECHNICAL_VISIT,
//   //     item: {
//   //       ...cachedTechnicalVisit.technicalVisit,
//   //       resourcesUpdatedAt: updatedAt,
//   //     },
//   //   }),
//   // );

//   // technicalVisit = yield select(technicalVisitPageSelector);

//   // yield put(appActions.addOrUpdateCachedTv(technicalVisit));
//   yield put(
//     appActions.addSyncablePath({
//       id: technicalVisitId,
//       path,
//       params,
//     }),
//   );
// }

function* exportImages(action: PayloadAction<ExportImagesParams>) {
  try {
    yield call(api.files.export, action.payload);
  } catch (e) {
    console.error(e);
  }
}

export function* technicalVisitPageSaga() {
  yield takeLatest(actions.fetchTechnicalVisit, fetchTechnicalVisit);
  yield takeLatest(actions.generatePdf, generatePdf);
  yield takeLatest(actions.addOrUpdateItem, addOrUpdateItem);
  // yield takeEvery(actions.fetchImages, fetchImages);
  yield takeLatest(actions.addOrUpdateImage, addOrUpdateImage);
  yield takeLatest(actions.deleteImage, deleteImage);
  yield takeLatest(actions.removeItem, removeItem);
  yield takeLatest(actions.exportImages, exportImages);
}
