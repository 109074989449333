import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, TextField } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { useFormikContext } from 'formik';
import { CustomerInterface, SocietyAttributedTo } from 'app/types';
import { AddressAutocomplete } from 'app/components/AddressAutocomplete';
import { useLocation } from 'react-router-dom';
import { TechnicalVisitPageState } from '../types';
import { SimpleSelect } from 'app/components/SimpleSelect';
import { upperFirst } from 'lodash';

export const Form = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setValidationSchema, variant } = useStepperContext();
  const {
    onSmallDevice,
    technicalVisitsCached,
    lists,
    isAdmin,
    isAdminNovita,
    isSuperadmin,
    isSupplier,
  } = useSelector(appDataSelector);
  const {
    setFieldValue,
    values: { address, zip, city, ...values },
  } = useFormikContext<CustomerInterface>();

  const technicalVisitId = React.useMemo(() => {
    const id = pathname.split('/').slice(-1)[0];
    return id;
  }, [pathname]);

  const item: TechnicalVisitPageState = React.useMemo(
    () => technicalVisitsCached[technicalVisitId],
    [technicalVisitsCached, technicalVisitId],
  );

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          firstname: yup.string().required(t(translations.formErrors.required)),
          lastname: yup.string().required(t(translations.formErrors.required)),
          address: yup.string().nullable(),
          city: yup.string().nullable(),
          zip: yup.string().nullable(),
          society: yup.string().nullable(),
          attributedTo: yup
            .string()
            .required(t(translations.formErrors.required)),
          coordinates: yup
            .string()
            .matches(
              /^(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)$/,
              t(translations.formErrors.coordinates),
            )
            .nullable(),
          phone: yup.string().nullable(),
          mail: yup.string().email(t(translations.formErrors.email)).nullable(),
          mobile: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {item?.technicalVisit && (
        <Box
          display="flex"
          flexDirection={onSmallDevice ? 'column' : 'row'}
          justifyContent="space-between"
          padding="10px"
          gridGap="1em"
        >
          <TextField
            variant="outlined"
            disabled
            value={item?.technicalVisit?.technicianName}
            label={t(translations.TechnicalVisitForms.labels.technician)}
          ></TextField>
          <TextField
            variant="outlined"
            disabled
            value={`${item?.technicalVisit?.selling} kWc (${
              lists?.find(
                (list) => list.id === item?.technicalVisit?.typeOfSelling,
              )?.name
            })`}
            label={t(translations.TechnicalVisitTable.selling)}
          ></TextField>
        </Box>
      )}
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="firstname"
          label={t(translations.CustomerForms.labels.firstname)}
          extraAttr={{
            ...variant,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        <FormikInput
          name="lastname"
          label={t(translations.CustomerForms.labels.lastname)}
          extraAttr={{
            ...variant,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
      </Box>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="society"
          label={t(translations.CustomerForms.labels.society)}
          extraAttr={{
            ...variant,
            fullWidth: true,
            disabled: isSupplier,
          }}
          containerStyle={{ flex: '4' }}
          component={TextField}
        />
      </Box>
      <AddressAutocomplete
        defaultValue={address ? `${address} - ${zip}, ${city}` : ''}
        label={t(translations.TechnicalVisitForms.labels.address)}
        disabled={isSupplier}
        manualAddressForm={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            bgcolor="#f0f0f0"
          >
            <FormikInput
              name="address"
              label={t(translations.TechnicalVisitForms.labels.address)}
              extraAttr={{
                variant: 'outlined',
                fullWidth: true,
                disabled: isSupplier,
              }}
              component={TextField}
            />
            <Box display="flex">
              <FormikInput
                name="zip"
                label={t(translations.TechnicalVisitForms.labels.zip)}
                extraAttr={{
                  variant: 'outlined',
                }}
                containerStyle={{ flex: '1' }}
                component={TextField}
              />
              <FormikInput
                name="city"
                label={t(translations.TechnicalVisitForms.labels.city)}
                extraAttr={{
                  disabled: isSupplier,
                  variant: 'outlined',
                  fullWidth: true,
                }}
                containerStyle={{ flex: '2' }}
                component={TextField}
              />
            </Box>
            <FormikInput
              name="coordinates"
              label={t(translations.TechnicalVisitForms.labels.coordinates)}
              extraAttr={{
                disabled: isSupplier,
                variant: 'outlined',
                fullWidth: true,
              }}
              component={TextField}
            />
          </Box>
        }
        onAddress={({ address, zip, city, coordinates }) => {
          setFieldValue('address', address);
          setFieldValue('zip', zip);
          setFieldValue('city', city);
          setFieldValue('coordinates', coordinates);
        }}
      />
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="phone"
          label={t(translations.CustomerForms.labels.phone)}
          extraAttr={{
            disabled: isSupplier,
            ...variant,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        <FormikInput
          name="mobile"
          label={t(translations.CustomerForms.labels.mobile)}
          extraAttr={{
            disabled: isSupplier,
            ...variant,
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
      </Box>
      <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
        <FormikInput
          name="mail"
          label={t(translations.CustomerForms.labels.mail)}
          extraAttr={{
            disabled: isSupplier,
            ...variant,
            onChange: (e) =>
              setFieldValue('mail', `${e.target.value}`.toLowerCase()),
          }}
          containerStyle={{ flex: '2' }}
          component={TextField}
        />
        {(isAdmin || isSuperadmin) && !isAdminNovita && SocietyAttributedTo && (
          <FormikInput
            name="attributedTo"
            label={t(translations.UserForms.labels.attributedTo)}
            extraAttr={{
              value: values?.['attributedTo'] || item.customer?.attributedTo,
              variant: 'outlined',
              options: Object.values(SocietyAttributedTo).map((v) => ({
                value: v,
                displayedValue: upperFirst(v),
              })),
              fullWidth: true,
              displayEmpty: false,
            }}
            containerStyle={{ flex: '2' }}
            component={SimpleSelect}
          />
        )}
      </Box>
    </>
  );
};
