export enum Roles {
  BASIC = 'basic',
  ADMIN = 'admin',
  BASIC_MANAGER = 'basicManager',
  ADMIN_LYON = 'adminLyon',
  ADMIN_NOVITA = 'adminNovita',
  ADMIN_AVIGNON = 'adminAvignon',
  SUPERADMIN = 'superadmin',
  SUPPLIER = 'supplier',
  SUBCONTRACTING = 'subcontracting',
}

export const {
  BASIC,
  ADMIN,
  SUPERADMIN,
  BASIC_MANAGER,
  ADMIN_NOVITA,
  ADMIN_LYON,
  ADMIN_AVIGNON,
  SUPPLIER,
  SUBCONTRACTING,
} = Roles;
