import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { translations } from 'locales/i18n';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Chip,
  Link,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { format } from 'date-fns';
import { dashboardPageSelector } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../slice';
import { formResources } from 'app/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    listFlex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gridGap: '15px',
    },
    content: {
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
      fontSize: '12px',
    },
    chipAction: {
      background: '#ffffff3d',
      borderRadius: '15px',
      marginLeft: '10px',
      fontSize: '20px',
      padding: '2px',
    },
  }),
);

interface Props {
  open?: boolean;
  id: string;
  handleClose: () => void;
}

export default function DialogHistory({
  open = false,
  handleClose,
  id,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = useSelector(dashboardPageSelector);
  const routeHistory = useHistory();

  React.useEffect(() => {
    fetchHistory();
  }, [open, id]);

  const fetchHistory = React.useCallback(() => {
    if (id) {
      dispatch(actions.fetchHistory({ 'technical-visits': id, mainId: id }));
    }
  }, [dispatch, id]);

  const classes = useStyles();

  const colorNIconAction = {
    create: {
      color: '#31B64E',
      Icon: <AddIcon className={classes.chipAction} />,
    },
    default: {
      color: 'e0e0e0',
      Icon: <CachedIcon className={classes.chipAction} />,
    },
    delete: {
      color: '#CD4040',
      Icon: <ClearIcon className={classes.chipAction} />,
    },
    update: {
      color: '#DA7237',
      Icon: <CachedIcon className={classes.chipAction} />,
    },
  };
  const typeIcon = {
    file: {
      Icon: <AttachFileIcon style={{ color: 'white', fontSize: '14px' }} />,
    },
    default: {
      Icon: <TextFieldsIcon style={{ color: 'white', fontSize: '14px' }} />,
    },
  };

  const determinateValue = (value: string) => {
    if (typeof value === 'boolean')
      return t(translations.TechnicalVisitForms.dialogHistory[!value ? 'unchecked' : 'checked']);
    return value || '';
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t(translations.TechnicalVisitForms.dialogHistory.title)}
      </DialogTitle>
      <DialogContent>
        {history[id]?.map((row, i) => (
          <List>
            <ListItem divider className={classes.listFlex}>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.heading}>
                  {`${t(
                    translations.TechnicalVisitForms.dialogHistory.actions[
                      row.action
                    ],
                    {
                      name: row.userFullname,
                      resource: t(
                        translations.TechnicalVisitForms.dialogHistory
                          .resources[row.resource.name],
                      ),
                    },
                  )}
                  ${
                    row.resource.description
                      ? ` (${row.resource.description})`
                      : ''
                  }`}
                  {/* if need set url link of technical-visit */}
                  {row.tags['url-id'] && (
                    <>
                      {row.action !== 'duplicate' ? 'à partir de ' : ''}
                      <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          routeHistory.push(
                            `/technical-visit/${row.tags['url-id']}`,
                          )
                        }
                      >
                        {row.action !== 'duplicate' ? 'celle-ci' : '(ouvrir)'}
                      </Link>
                    </>
                  )}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {`${format(new Date(row.createdAt), 'dd/MM/yy, HH:mm')}`}
                </Typography>
              </Box>
              {row.attributes && (
                <Box display="flex" flexWrap="wrap" gridGap="10px">
                  {Object.keys(row.attributes).map((attr) => (
                    <Tooltip
                      title={`${
                        t(
                          translations.StatusesIcons[row.attributes[attr].old],
                        ) || determinateValue(row.attributes[attr].old)
                      } => ${
                        t(
                          translations.StatusesIcons[
                            row.attributes[attr].current
                          ],
                        ) || determinateValue(row.attributes[attr].current)
                      } `}
                      placement="bottom"
                      disableHoverListener={
                        !row.attributes[attr].old &&
                        !row.attributes[attr].current
                      }
                    >
                      <Chip
                        icon={
                          typeIcon[row.attributes[attr]?.type || 'default'].Icon
                        }
                        label={
                          <>
                            {t(
                              translations[formResources[row.resource.name]]
                                ?.labels[attr],
                            )}
                            {
                              colorNIconAction?.[
                                row.attributes[attr]?.action || 'default'
                              ].Icon
                            }
                          </>
                        }
                        style={{
                          color: 'white',
                          background:
                            colorNIconAction?.[
                              row.attributes[attr]?.action || 'default'
                            ].color,
                        }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              )}
            </ListItem>
          </List>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          variant="outlined"
          color="primary"
        >
          {t(translations.ConfirmDialog.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
