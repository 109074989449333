import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';

import { translations } from 'locales/i18n';
import {
  BASIC,
  SUBCONTRACTING,
  SUPERADMIN,
  UserInterface,
  ADMIN_LYON,
  ADMIN_NOVITA,
  ADMIN_AVIGNON,
  BASIC_MANAGER,
  States,
  SUPPLIER,
} from 'types';
import { appDataSelector } from 'app/selectors';
import { actions } from './slice';
import { actions as appActions } from 'app/slice';

import { Typography, Box, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormikInput } from 'app/components/FormikInput';
import { SimpleSelect, MenuOption } from 'app/components/SimpleSelect';
import { FloatingSubmitBtn } from 'app/components/FloatingSubmitBtn';
import { AutoCompleteSelect } from 'app/components/AutoCompleteSelect';
import { MultipleSelect } from 'app/components/CustomSelect';

interface Props {
  user: UserInterface;
  withAdminDetails: boolean;
  offline: boolean;
  onForceCloseDialog: () => void;
}

type OptionsStateInterface = [
  [] | MenuOption[],
  React.Dispatch<React.SetStateAction<[] | MenuOption[]>>,
];

export const DetailsForm = ({
  user,
  withAdminDetails,
  offline,
  onForceCloseDialog,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lists } = useSelector(appDataSelector);
  const { isAdmin, isCallingApi, isSuperadmin } = useSelector(appDataSelector);
  const [rolesOptions, setRolesOptions] = React.useState(
    [],
  ) as OptionsStateInterface;
  const [society, setSociety] = React.useState([]) as OptionsStateInterface;

  const notificationOptions = Object.values(States).map((value) => ({
    value,
    displayedValue: t(translations.StatusesIcons[value]),
  }));
  React.useEffect(() => {
    dispatch(appActions.fetchLists(['society']));
  }, [dispatch, society]);

  React.useEffect(() => {
    const currentList = lists.find((l) => l.key === 'society')?.sublists;
    setSociety(
      currentList?.map((cl) => ({
        value: cl.key,
        displayedValue: cl.name,
      })) || [],
    );
  }, [setSociety, lists]);

  const variant = { variant: 'outlined' };

  const nameValidation = yup
    .string()
    .matches(
      /^[a-zA-Z\u00C0-\u017F\s-]+$/,
      t(translations.formErrors.onlyLetters),
    )
    .required(t(translations.formErrors.required));
  const digitsValidation = yup
    .string()
    .matches(/^[\d]+$/, t(translations.formErrors.onlyDigits));
  const langOptions = [
    {
      value: 'fr',
      displayedValue: 'Français',
    },
    {
      value: 'en',
      displayedValue: 'English',
    },
  ];

  React.useEffect(() => {
    const options = _.compact([
      ADMIN_LYON,
      ADMIN_AVIGNON,
      ADMIN_NOVITA,
      BASIC_MANAGER,
      BASIC,
      SUPPLIER,
      SUBCONTRACTING,
      isSuperadmin && SUPERADMIN,
    ]).map((role) => ({
      value: role,
      displayedValue: t(translations.UserForms.roles[role]),
    }));

    setRolesOptions(options);
  }, [setRolesOptions, isAdmin]);

  const createSociety = (society) => {
    dispatch(
      appActions.updateList({
        parentId: lists!.find((l) => l.key === 'society')!.id,
        key: society.replace(' ', '_'),
        name: society,
      }),
    );
    setSociety((s) => [
      ...s,
      { value: society.replace(' ', '_'), displayedValue: society },
    ]);
  };

  return (
    <>
      <Typography variant="h6">
        {t(translations.UserForms.detailsTitle)}
      </Typography>
      <Formik
        initialValues={user}
        enableReinitialize={true}
        validationSchema={yup.object({
          email: yup
            .string()
            .email(t(translations.formErrors.email))
            .required(t(translations.formErrors.required)),
          givenName: nameValidation,
          familyName: nameValidation,
          phoneNumber: digitsValidation,
          notifications: yup.array().default([]),
          lang: yup
            .string()
            .oneOf(['fr', 'en'], t(translations.formErrors.lang))
            .required(t(translations.formErrors.required)),
          ...(withAdminDetails
            ? {
                role: yup
                  .string()
                  .oneOf([
                    SUPERADMIN,
                    BASIC,
                    ADMIN_LYON,
                    ADMIN_NOVITA,
                    ADMIN_AVIGNON,
                    BASIC_MANAGER,
                    SUPPLIER,
                    SUBCONTRACTING,
                  ])
                  .required(t(translations.formErrors.required)),
                society: yup
                  .string()
                  .required(t(translations.formErrors.required)),
              }
            : {}),
        })}
        onSubmit={(user) => {
          dispatch(actions.addOrUpdateUser(user));
          _.isEmpty(user.id) && onForceCloseDialog();
        }}
      >
        {({ values, setFieldValue }) => (
          <Box display="flex" alignItems="center" component={Form}>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <FormikInput
                  name="givenName"
                  label={t(translations.UserForms.labels.firstName)}
                  extraAttr={{ variant: 'outlined' }}
                  containerStyle={{ flex: '2' }}
                  component={TextField}
                />
                <FormikInput
                  name="familyName"
                  label={t(translations.UserForms.labels.lastName)}
                  extraAttr={{ variant: 'outlined' }}
                  containerStyle={{ flex: '2' }}
                  component={TextField}
                />
                <FormikInput
                  name="lang"
                  label={t(translations.UserForms.labels.lang)}
                  extraAttr={{
                    variant: 'outlined',
                    options: langOptions,
                    fullWidth: true,
                  }}
                  containerStyle={{ flex: '1' }}
                  component={SimpleSelect}
                />
              </Box>

              <Box display="flex">
                <FormikInput
                  name="email"
                  label={t(translations.UserForms.labels.email)}
                  extraAttr={{ variant: 'outlined', disabled: user.id }}
                  containerStyle={{ flex: '3' }}
                  component={TextField}
                />
                <FormikInput
                  noPaddingLeft
                  name="phoneNumber"
                  label={t(translations.UserForms.labels.phoneNumber)}
                  extraAttr={{ variant: 'outlined' }}
                  containerStyle={{ flex: '3' }}
                  component={TextField}
                />
              </Box>
              <Box display="flex">
                <FormikInput
                  name="notifications"
                  label={t(translations.UserForms.labels.notifications)}
                  extraAttr={{
                    options: notificationOptions,
                    fullWidth: true,
                    canClear: false,
                  }}
                  containerStyle={{ minWidth: '250px', maxWidth: '420px' }}
                  component={MultipleSelect}
                />
              </Box>

              {withAdminDetails && (
                <Box display="flex">
                  <FormikInput
                    name="role"
                    label={t(translations.UserForms.labels.role)}
                    extraAttr={{
                      ...variant,
                      options: rolesOptions,
                      fullWidth: true,
                    }}
                    containerStyle={{ flex: '1' }}
                    component={SimpleSelect}
                  />
                  <FormikInput
                    name="society"
                    label={t(translations.UserForms.labels.society)}
                    extraAttr={{
                      ...variant,
                      options: society,
                      canDisplayAdd: isSuperadmin,
                      addItem: (item) => createSociety(item),
                      onChange: (e) => setFieldValue('society', e),
                    }}
                    containerStyle={{ flex: '2' }}
                    component={AutoCompleteSelect}
                  />
                </Box>
              )}
            </Box>

            <FloatingSubmitBtn
              dataTestId="details-form-submit"
              disabled={isCallingApi || offline}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};
