import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { translations } from 'locales/i18n';
import { appDataSelector } from 'app/selectors';
import { useStepperContext } from '../Context';

import { Box, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { FormikInput } from 'app/components/FormikInput';
import { MenuOption, SimpleSelect } from 'app/components/SimpleSelect';
import { GeneratorInterface, TechnicalVisitPageState } from '../types';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

export const Form = () => {
  const { pathname } = useLocation();

  const { values, setFieldValue } = useFormikContext<GeneratorInterface>();
  const { t } = useTranslation();
  const { setValidationSchema, variant } = useStepperContext();
  const {
    onSmallDevice,
    lists,
    isSupplier,
    isAdminNovita,
    technicalVisitsCached,
  } = useSelector(appDataSelector);
  const [listsOptions, setListsOptions] = React.useState(
    {} as { [key: string]: MenuOption[] },
  );
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  const [listKeys] = React.useState([
    'connection_type',
    'power',
    'ecs_production',
    'main_heating_type',
    'presence_room_thermostat',
    'battery_power',
    'secondary_heating_type',
  ]);

  React.useEffect(() => {
    setRefresh(true);

    const options = {};
    listKeys.map((key) => {
      const currentList = lists.find((l) => l.key === key)?.sublists;
      options[key] = currentList?.map((cl) => ({
        value: cl.id,
        displayedValue: cl.name,
      })) as MenuOption[];
    });
    setListsOptions(options);
  }, [setListsOptions, lists, listKeys]);

  React.useEffect(
    () =>
      setValidationSchema(
        yup.object({
          annualConsumption: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          nbrOfModule: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          unitPowerModule: yup
            .number()
            .nullable()
            .typeError(t(translations.formErrors.onlyDigits)),
          typeConnection: yup.number().nullable(),
          storageBatteryPower: yup.number().nullable(),
          ecsProduction: yup.number().nullable(),
          ecsConnection: yup.number().nullable(),
          mainHeatingType: yup.number().nullable(),
          secondaryHeatingType: yup.number().nullable(),
          remoteWithDisplay: yup.boolean().default(false),
          btBrandModel: yup.string().nullable(),
          presenceRoomThermostat: yup.number().nullable(),
          furtherInformation: yup.string().nullable(),
        }),
      ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const technicalVisitId = React.useMemo(() => {
    const id = pathname.split('/').slice(-1)[0];
    return id;
  }, [pathname]);

  const item: TechnicalVisitPageState = React.useMemo(
    () => technicalVisitsCached[technicalVisitId],
    [technicalVisitsCached, technicalVisitId],
  );

  return (
    <>
      {!refresh && (
        <Box display="flex" flexDirection={onSmallDevice ? 'column' : 'row'}>
          <Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="annualConsumption"
                label={t(translations.GeneratorForms.labels.annualConsumption)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  style: { width: '100%' },
                  onChange: (e) =>
                    setFieldValue(
                      'annualConsumption',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '4' }}
                component={TextField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="nbrOfModule"
                label={t(translations.GeneratorForms.labels.nbrOfModule)}
                extraAttr={{
                  ...variant,
                  style: { width: '100%' },
                  disabled: isSupplier,
                }}
                containerStyle={{ flex: '2' }}
                component={TextField}
              />
              <FormikInput
                name="unitPowerModule"
                label={t(translations.GeneratorForms.labels.unitPowerModule)}
                extraAttr={{
                  ...variant,
                  style: { width: '100%' },
                  disabled: isSupplier,
                  onChange: (e) =>
                    setFieldValue(
                      'unitPowerModule',
                      e.target.value
                        ? e.target.value.replaceAll(',', '.')
                        : null,
                    ),
                }}
                containerStyle={{ flex: '2' }}
                component={TextField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="typeConnection"
                label={t(translations.GeneratorForms.labels.typeConnection)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options:
                    listsOptions['connection_type']?.map((option) => {
                      console.log(option);
                      if (option.displayedValue === 'DSI') {
                        option.displayedValue =
                          isAdminNovita ||
                          item.customer.attributedTo === 'novita'
                            ? 'MSB'
                            : 'DSI';
                      }
                      return option;
                    }) || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '2' }}
                component={SimpleSelect}
              />
              <FormikInput
                name="storageBatteryPower"
                label={t(
                  translations.GeneratorForms.labels.storageBatteryPower,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['battery_power'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '2' }}
                component={SimpleSelect}
              />
            </Box>

            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
              marginTop="1em"
            >
              <FormikInput
                name="ecsProduction"
                label={t(translations.GeneratorForms.labels.ecsProduction)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['ecs_production'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />

              <FormikInput
                name="ecsConnection"
                label={t(translations.GeneratorForms.labels.ecsConnection)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['power'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              {true && (
                <FormikInput
                  name="btBrandModel"
                  label={t(translations.GeneratorForms.labels.btBrandModel)}
                  extraAttr={{
                    ...variant,
                    style: { width: '100%' },
                    disabled: isSupplier,
                  }}
                  containerStyle={{ flex: '4' }}
                  component={TextField}
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="mainHeatingType"
                label={t(translations.GeneratorForms.labels.mainHeatingType)}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['main_heating_type'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />

              <FormikInput
                name="remoteWithDisplay"
                label={t(translations.GeneratorForms.labels.remoteWithDisplay)}
                extraAttr={{
                  disabled: isSupplier,
                  labelPlacement: 'bottom',
                  checked: values?.['remoteWithDisplay'] || false,
                  control: <Switch size="small" />,
                }}
                containerStyle={{
                  flex: '2',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                component={FormControlLabel}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="secondaryHeatingType"
                label={t(
                  translations.GeneratorForms.labels.secondaryHeatingType,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['secondary_heating_type'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={onSmallDevice ? 'column' : 'row'}
            >
              <FormikInput
                name="presenceRoomThermostat"
                label={t(
                  translations.GeneratorForms.labels.presenceRoomThermostat,
                )}
                extraAttr={{
                  ...variant,
                  disabled: isSupplier,
                  options: listsOptions['presence_room_thermostat'] || [],
                  style: { width: '100%' },
                }}
                containerStyle={{ flex: '3' }}
                component={SimpleSelect}
              />
            </Box>
          </Box>
          <Box>
            <FormikInput
              name="furtherInformation"
              label={t(translations.RoofFrameForms.labels.furtherInformation)}
              extraAttr={{
                ...variant,
                disabled: isSupplier,
                rows: 15,
                multiline: true,
                style: { width: '100%' },
              }}
              containerStyle={{ flex: '6' }}
              component={TextField}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
